@tailwind base;
@tailwind components;
@tailwind utilities;

.link {
  @apply text-blue-600 dark:text-blue-400;
}

.prose {
  @apply max-w-none;
}
